<template>
    <v-container>
        <v-form ref="form" v-model="valid" lazy-validation>
            <v-icon large color="blue-grey darken-2">mdi-account-circle</v-icon>
            <v-subheader>Login</v-subheader>

            <!-- Error Message Alert -->
            <v-alert v-if="errorMessage" type="error" role="alert">{{ errorMessage }}</v-alert>

            <v-text-field label="Email" v-model="email" :rules="emailRules" required aria-labelledby="Email"
                @keyup.enter="login"></v-text-field>

            <v-text-field label="Password" v-model="password" :rules="passwordRules" type="password" required
                aria-labelledby="Password" @keyup.enter="login"></v-text-field>

            <p>
                <br />
                <router-link to="/password-reset" class="mt-3">Forgot Password?</router-link>
            </p>

            <v-btn @click="login" :disabled="!valid || loading" color="primary">
                <template v-if="loading">
                    <v-progress-circular indeterminate></v-progress-circular>
                </template>
                <template v-else>
                    Login
                </template>
            </v-btn>
        </v-form>
        <br />
        <p>Or</p>
        <v-btn @click="signInWithGoogle" color="white">
            <v-img src="@/assets/google-logo.png" aspect-ratio="1" class="mr-2" contain height="24" min-width="24"></v-img>
            <template v-if="loadingGoogle">
                <v-progress-circular indeterminate></v-progress-circular>
            </template>
            <template v-else>
                Sign In with Google
            </template>
        </v-btn>
        <br />
        <br />
        <p>
            Don't have an account?
            <router-link to="/register" class="mt-3">Create an account</router-link>
        </p>
    </v-container>
</template>
  

<script>
// Import Firebase Auth (Modular SDK v10.1.0)
import { getAuth, GoogleAuthProvider, signInWithEmailAndPassword, signInWithPopup, getAdditionalUserInfo } from 'firebase/auth';
import { getFirestore, doc, setDoc, getDoc } from 'firebase/firestore';
import { mapGetters } from "vuex";
import { getAnalytics, logEvent } from 'firebase/analytics';
const analytics = getAnalytics();

export default {
    data: () => ({
        valid: true,
        email: "",
        password: "",
        errorMessage: "", // Store user-friendly error messages
        loading: false,
        loadingGoogle: false,
        selectedPlan: "Free",
        emailRules: [
            (v) => !!v || "E-mail is required",
            (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
        ],
        passwordRules: [
            (v) => !!v || "Password is required",
        ],
    }),
    computed: {
        ...mapGetters([
            'user'
        ]),
    },
    methods: {
        async login() {
            this.loading = true; // Set loading state
            if (this.$refs.form.validate()) {
                const auth = getAuth();
                try {
                    const userCredential = await signInWithEmailAndPassword(auth, this.email, this.password);
                    const user = userCredential.user;

                    // Handle verified user and route them
                    if (user.emailVerified) {
                        this.errorMessage = "";
                        this.$router.push('/');
                    } else {
                        this.errorMessage = "Please verify your email before logging in.";
                        this.$router.push('/verify-email');
                    }

                } catch (error) {
                    this.errorMessage = this.getErrorMsg(error.code); // Method to get error message
                    console.error("Error signing in: ", error);
                }
            }
            this.loading = false; // Reset loading state
        },
        getErrorMsg(code) {
            const errorMessages = {
                'auth/wrong-password': "Incorrect password. Please try again.",
                'auth/user-not-found': "No user found with this email address.",
                'auth/invalid-email': "Invalid email address.",
            };
            return errorMessages[code] || "Failed to log in. Please try again later.";
        },
        async signInWithGoogle() {
            const provider = new GoogleAuthProvider();
            const auth = getAuth();
            const db = getFirestore();
            this.loadingGoogle = true;  // Start the loadingGoogle spinner or some other user feedback

            try {
                // Step 1: Sign in the user with Google
                const result = await signInWithPopup(auth, provider);
                // Check if the user is new or existing
                const additionalUserInfo = getAdditionalUserInfo(result);
                const isNewUser = additionalUserInfo ? additionalUserInfo.isNewUser : undefined;

                if (isNewUser === undefined) {
                    console.error("additionalUserInfo is undefined");
                    return;
                }


                // Step 2: Get the user object from the result
                const user = result.user;

                const db = getFirestore();
                const userDoc = doc(db, 'users', user.uid);
                const userDocSnapshot = await getDoc(userDoc);

                // If the user is new or the document doesn't exist, set the document
                if (isNewUser || !userDocSnapshot.exists()) {
                    await setDoc(userDoc, {
                        displayName: user.displayName,
                        createdAt: new Date(),
                        last_usage_date: new Date(),
                        usage_count: 0,
                        emailVerified: true,
                    });
                    // Create Stripe customer for new user
                    const token = this.user.accessToken;
                    const headers = {
                        'Authorization': `Bearer ${token}`
                    };
                    await axios.post(`${process.env.VUE_APP_API_ADDRESS}/create_stripe_customer`, {
                        user_id: user.uid,
                        email: user.email,
                        subscription_level: this.selectedPlan,
                    }, {
                        headers: headers
                    });
                    // Log signup event only for new users
                    logEvent(analytics, 'signup_success');
                }
                // Step 5: Navigate to the homepage
                this.$router.push('/');

            } catch (error) {
                console.error("Error signing in with Google or creating user document: ", error);
            } finally {
                this.loadingGoogle = false;  // End the loadingGoogle spinner or provide other user feedback
            }
        }
    },

};
</script>